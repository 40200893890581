<template>
  <div id="buttonDiv"></div>
</template>

<script>
export default {
  name: 'GIdentitySigninButton',

  methods: {
      handleCredentialResponse(response) {
          const google_token = response
          //console.log(google_token)
          this.$emit('success', google_token)
      },
  },

  mounted: function () {
    window.google.accounts.id.initialize({
        client_id: "620451539712-3kdjs1qt51gljloi2rdsg1udgg41kdfh.apps.googleusercontent.com",
        referrerPolicy: {policy: 'strict-origin-when-cross-origin'},
        callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
    );
  }
}
</script>

<style scoped>
</style>